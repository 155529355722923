import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="fine-box central pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Our Centralized Cryptocurrency Exchange <span className="bluecolor">Development Approach</span>
            </h2>
            <p className="text-center mb-2">
            We follow a definitive step-by-step process to acquire the prompt working centralized exchange development platform. Experience the difference that sets our projects apart.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <p className="head3">Requirements and Planning</p>
                <p className="pharagraph">
                First of all, our development team begins with collecting your valuable requirements and planning everything starting from meeting your goals, objectives, target audience, etc. Then we will curate a mindmap of a detailed plan that aligns with your success.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Designing UI/UX</p>
                <p className="pharagraph">
                Our experts will indulge in crafting fine wireframes, and prototypes for projecting a stunning visualization to your investors. We provide an eye-popping UI/UX that grabs your user's attention. Thus ensuring that every interaction within the exchange platform is both vibrant and user-friendly.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Coding Development</p>
                <p className="pharagraph">
                In this phase, a centralized exchange platform is developed using cutting-edge technologies. We will give you the full-fledged CEX platform by incorporating advanced functionalities and robust security mechanisms. Our development process brings your platform to life, ensuring its success.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Quality Check & Testing</p>
                <p className="pharagraph">
                Our supreme developers will thoroughly check and test the project at every stage. The testing team at Coinsclone will rigorously test each feature and functionality to provide you with quality assurance. We always ensure a flawless performance of your marketplace.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Deployment</p>
                <p className="pharagraph">
                Once the development phase is completed, our team will safely deploy the exchange platform & integrate it with necessary APIs, payment gateways, and security features. Our deployment process configures the environment to ensure our exchange meets your standards.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Support and Maintenance</p>
                <p className="pharagraph">
                Our service doesn't end after deployment, we will prioritize robust support and maintenance for your CEX platform. Our support team will assist in monitoring platform performance and provide timely updates, feature enhancements, etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
