import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class Revenue extends React.Component {

  render() {
    return (
        <section className="dapps central pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Revenue Streams</span> Of Centralized Exchange Software</h2>
            <p className='text-center'>Centralized exchange software development generates revenue via multiple streams, each designed to enhance profitability. Here's a breakdown of the top revenue stream</p>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Trading Fees</h3>
                            <p className='pharagraph'>Trading fees are the backbone of revenue generation. You can get a small percentage on every trade executed on your CEX platform. This consistent income source grows alongside the trading volume, which makes it a huge and reliable revenue stream for your exchanges.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Withdrawal Fees</h3>
                            <p className='pharagraph'>Withdrawal fees are charged when your users transfer funds or assets in your exchange. These fees ensure a steady revenue flow and transaction processing costs that support the platform's financial sustainability.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Listing Fees</h3>
                            <p className='pharagraph'>Listing fees are charged to cryptocurrency projects that want their tokens available on your exchange. By offering visibility to new tokens and projects, your exchange can earn significant revenue.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Staking and Lending Services</h3>
                            <p className='pharagraph'>Staking and lending features attract long-term users and generate income via commissions on staking rewards or interest from lending. This allows your CEX platform to meet the growing demand for passive income opportunities in the crypto space.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Margin Trading & Liquidation Fees</h3>
                            <p className='pharagraph'>Your centralized exchange platform can earn revenue by charging interest on funds borrowed for margin trading and imposing fees during liquidations when collateral is insufficient. This attracts advanced traders who take big risks in exchange for the chance of earning large profits.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Token Launchpad (IEO Fees)</h3>
                            <p className='pharagraph'>Initial Exchange Offerings (IEOs) provide a platform for new projects to launch their tokens. Your exchange can earn substantial fees by facilitating these launches, thus exposing projects to a broad user base.</p>
                        </div>
                    </div>
          </div>
          <div className="mt-3 text-center">
                  <ButtonComponent />
              </div>
        </div>
      </section>

    )
  }
}

export default Revenue
