import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>Centralized Exchange</span> Development Company</h1>
                <p className='pharagraph'>Coinsclone, a Centralized Exchange Development Company, redefines the future of your financial market. With our Centralized Exchange Development services, we help you rise above the competition. Get infrastructure modules and integrations to build sophisticated exchange platforms that guarantee strong revenue streams.</p>
                <p className='pharagraph mb-0'>We make your exchange platform for profitable trades, where every click counts!</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/central/centralized-exchange-development-company.webp"
                alt="centralized exchange development company banner"
                className='d-none d-md-block float-end'
                width={545}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection