import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class DevProcess extends React.Component {

  render() {
    return (
      <section className="process-of central pt-100 mb-0">
            <div className="container">     
              <h2 className="heading-h2 text-center">Centralized Exchange Development Services <span className="bluecolor">We offer</span> </h2>
              <p className="text-center">
              We offer exclusive centralized exchange development services to transform your crypto vision into reality.
              </p>
              <div className="d-lg-flex add-one">
                <div className="square" >
                  <h3>Exchange Consulting Services</h3>
                  <p className="pharagraph">
                  We, as a top cryptocurrency exchange development company, provide consultations for your business. Our developer team helps you define your business objectives, market positioning, and growth strategies, as well as guidance to make it a success.
                  </p>
                </div>
                <div className="square">
                  <h3>Custom Exchange Development</h3>
                  <p className="pharagraph">
                  With our centralized crypto exchange services, you can customize the building of your exchange platform. To satisfy your requirements, our developer team will construct a high-performing exchange platform as per your words. 
                  </p>
                </div>
                <div className="square" >
                  <h3>UI / UX Design</h3>
                  <p className="pharagraph">
                  We indulge in providing a user-friendly interface for your users. For both traders and administrators, we have given the most appealing and easy-to-navigate visuals.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-two">
                <div className="square" >
                  <h3>Exchange Platform Integration</h3>
                  <p className="pharagraph">
                  Our integration services include trading pairs, order-matching algorithms, liquidity management, wallet integration, KYC/AML compliance, and more. We also help with the addition and modifications of features as well.
                  </p>
                </div>
                <div className="square">
                  <h3>Security Features Implementation</h3>
                  <p className="pharagraph">
                  By choosing us for your centralized crypto exchange development, you can get robust security features including two-factor authentication, KYC/AML, DDoS protection, and audit trails to ensure safe transactions.
                  </p>
                </div>
                <div className="square" >
                  <h3>White Label Crypto Exchange</h3>
                  <p className="pharagraph">
                  Our expert development team provides you with a pre-built exchange platform tailored for your business. This allows you to launch your exchange quickly without the hassle of starting from scratch.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-three">
                <div className="square" >
                  <h3>Mobile App Development</h3>
                  <p className="pharagraph">
                  Our centralized exchange development services include exclusive mobile applications for both iOS and Android platforms to enhance user comfort. This allows your users to conveniently access the exchange on their smartphones or tablets.
                  </p>
                </div>
                <div className="square">
                  <h3>Analytics and Reporting</h3>
                  <p className="pharagraph">
                  As a centralized exchange development service provider, we offer the implementation of analytics and reporting tools to provide valuable insight into your user's trading activities, user behavior, market trends, and performance metrics.
                  </p>
                </div>
                <div className="square" >
                  <h3>Support and Maintenance</h3>
                  <p className="pharagraph">
                  As a CEX platform development company, we offer 24/7 technical assistance to resolve issues and ensure the smooth functioning of your platform. Additionally, our team provides regular maintenance and updates to keep your platform running efficiently.
                  </p>
                </div>
              </div>
              <div className="mt-3 text-center">
                  <ButtonComponent />
              </div>
            </div>
      </section>

    )
  }
}

export default DevProcess
