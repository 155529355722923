import React from 'react'



class WeOffer extends React.Component {


  render() {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h2 className="heading-h2">Top Centralized Crypto Exchange Clone Script <span className="bluecolor">We Offer</span></h2>
          <p className="text-center">A centralized exchange script is a pre-built solution with all the features you need, from design and development to deployment and post-launch support. Build your own CEX platform with our premium clone script solutions. Explore our top-rated options and launch your exchange to life effortlessly!</p>
          <div className="factor">
            <div className="revenue">
              <a href='https://www.coinsclone.com/binance-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/binance-logo.png" alt="Binance" />
              <span>Binance Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/bitfinex-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/central/bitfinex.png" alt="Bitfinex Clone" /><span>Bitfinex Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/okx-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/central/okx.png" alt="OKX Clone" /><span>OKX Clone Script</span></p></a>
            </div>
            <div className="revenue">
              <a href='https://www.coinsclone.com/coinbase-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/central/coinbase.png" alt="Coinbase clone" /><span>Coinbase Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/huobi-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/central/houbi.png" alt="Huobi Clone" /><span>Huobi Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/bitstamp-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/central/bitstamp.png" alt="Bitstamp Clone" /><span>Bitstamp Clone Script</span></p></a>
            </div>
            <div className="revenue">
              <a href='https://www.coinsclone.com/kraken-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/central/kraken.png" alt="Kraken Clone" /><span>Kraken Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/kucoin-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/central/kucoin.png" alt="KuCoin Clone" /><span>KuCoin Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/bybit-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/bybit.png" alt="Bybit Clone Script logo" /><span>Bybit Clone Script</span></p></a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WeOffer